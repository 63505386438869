:root {
  --1280-button-text-2-font-family: "Lora", Helvetica;
  --1280-button-text-2-font-size: 23px;
  --1280-button-text-2-font-style: normal;
  --1280-button-text-2-font-weight: 600;
  --1280-button-text-2-letter-spacing: 0px;
  --1280-button-text-2-line-height: 120.00000476837158%;
  --1280-button-text-font-family: "DM Sans", Helvetica;
  --1280-button-text-font-size: 18px;
  --1280-button-text-font-style: normal;
  --1280-button-text-font-weight: 400;
  --1280-button-text-letter-spacing: 0px;
  --1280-button-text-line-height: 120.00000476837158%;
  --1280-h1-large-font-family: "Lora", Helvetica;
  --1280-h1-large-font-size: 60px;
  --1280-h1-large-font-style: normal;
  --1280-h1-large-font-weight: 500;
  --1280-h1-large-letter-spacing: 0px;
  --1280-h1-large-line-height: 120.00000476837158%;
  --1280-h2-medium-font-family: "Lora", Helvetica;
  --1280-h2-medium-font-size: 50px;
  --1280-h2-medium-font-style: normal;
  --1280-h2-medium-font-weight: 500;
  --1280-h2-medium-letter-spacing: 0px;
  --1280-h2-medium-line-height: 120.00000476837158%;
  --1280-h3-small-font-family: "Lora", Helvetica;
  --1280-h3-small-font-size: 35px;
  --1280-h3-small-font-style: normal;
  --1280-h3-small-font-weight: 600;
  --1280-h3-small-letter-spacing: 0px;
  --1280-h3-small-line-height: 120.00000476837158%;
  --1280-h4-font-family: "Lora", Helvetica;
  --1280-h4-font-size: 28px;
  --1280-h4-font-style: normal;
  --1280-h4-font-weight: 600;
  --1280-h4-letter-spacing: 0px;
  --1280-h4-line-height: 120.00000476837158%;
  --1280-h5-font-family: "Lora", Helvetica;
  --1280-h5-font-size: 23px;
  --1280-h5-font-style: normal;
  --1280-h5-font-weight: 600;
  --1280-h5-letter-spacing: 0px;
  --1280-h5-line-height: 120.00000476837158%;
  --1280-quotes-font-family: "DM Sans", Helvetica;
  --1280-quotes-font-size: 23px;
  --1280-quotes-font-style: italic;
  --1280-quotes-font-weight: 400;
  --1280-quotes-letter-spacing: 0px;
  --1280-quotes-line-height: 120.00000476837158%;
  --1280-text-font-family: "DM Sans", Helvetica;
  --1280-text-font-size: 18px;
  --1280-text-font-style: normal;
  --1280-text-font-weight: 400;
  --1280-text-letter-spacing: 0px;
  --1280-text-line-height: 120.00000476837158%;
  --variable-collection-bg: rgba(18, 33, 49, 1);
  --variable-collection-white-50: rgba(136, 144, 152, 1);
  --white: rgba(255, 255, 255, 1);
}
